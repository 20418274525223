(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("ReactDOM"), require("sbRespBlockLib"), require("sbInternalMsgBus"), require("sbJsExtends"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-forgot-password-block", ["React", "mobx", "mobxReact", "ReactDOM", "sbRespBlockLib", "sbInternalMsgBus", "sbJsExtends"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-forgot-password-block"] = factory(require("React"), require("mobx"), require("mobxReact"), require("ReactDOM"), require("sbRespBlockLib"), require("sbInternalMsgBus"), require("sbJsExtends"));
	else
		root["sb-responsive-forgot-password-block"] = factory(root["React"], root["mobx"], root["mobxReact"], root["ReactDOM"], root["sbRespBlockLib"], root["sbInternalMsgBus"], root["sbJsExtends"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__84__) {
return 